import { PUBLIC_API_BASE_URL, PUBLIC_SENTRY_DSN } from '$env/static/public';
import { setApiBaseUrl } from '@luxfit/backend-api';
import * as Sentry from '@sentry/sveltekit';
import { dev } from '$app/environment';

setApiBaseUrl(PUBLIC_API_BASE_URL);

Sentry.init({
    enabled: !dev,
    dsn: PUBLIC_SENTRY_DSN,
    tracesSampleRate: 0.1,
    integrations: [Sentry.replayIntegration()],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0
});

export const handleError = Sentry.handleErrorWithSentry();
